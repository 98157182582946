<template>
	<v-container>
		<v-row class="text-center">
			<v-col cols="8" offset="2">
				<v-card elevation="2" outlined shaped tile>
					<v-card-text style="font-size: 16px"
						>It looks like your company's subscription is no longer active. Please contact
						<a href="mailto:help@liveswitch.com" target="_blank">help@liveswitch.com</a> if this is an error
						or if you would like to renew your subscription.</v-card-text
					>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		name: "BlockedUser",
	};
</script>
