<template>
    <v-dialog
      persistent
      v-model="dialog"
      width="auto"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5" style="padding-left:16px">
          Your Free Trial Has Ended!
        </v-card-title>
        <div>
          <v-card-text>Upgrade your account today to continue using LiveSwitch Contact. Still not sure? Contact help@liveswitch.com to extend your trial.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              variant="text"
              @click="$emit('upgrade')"
            >
              Upgrade
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'ExpiredTrialDialog',
  props: {
    isSubscriptionInactive: Boolean,
    isSubscriptionOwner: Boolean
  },
  data () {
      return {
        dialog: true,
      }
  },
}
</script>
