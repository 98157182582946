const installPrompt = {
	prompt: undefined,
};

installPrompt.setPrompt = function (prompt) {
	this.prompt = prompt;
};

installPrompt.installAvailable = function () {
	return this.prompt != null;
};

installPrompt.triggerInstall = async function () {
	await this.prompt?.prompt();
};

export default installPrompt;
