<template>
	<v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
		<v-card>
			<v-toolbar dark :color="options.color" dense flat>
				<v-toolbar-title class="white--text">Create New Conversation</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="pa-4">
				<v-form lazy-validation ref="formRef">
					<v-text-field
						outlined
						v-model="conversation.name"
						label="Name *"
						:maxLength="25"
						counter="25"></v-text-field>
					<div class="text-h7">* indicates a required field</div>
				</v-form>
			</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn rounded elevation="0" @click.native="cancel">Back</v-btn>
				<v-btn
					rounded
					elevation="0"
					color="primary"
					@click.native="agree"
					:loading="creating"
					:disabled="createButtonDisabled()"
					>{{ creating ? "Creating" : "Create" }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import api from "../api";
	export default {
		data() {
			return {
				dialog: false,
				resolve: null,
				reject: null,
				formRef: false,
				message: null,
				conversation: {
					name: "",
					projectId: "",
				},
				creating: false,
				options: {
					color: "primary",
					width: 460,
					zIndex: 200,
				},
			};
		},
		async mounted() {},
		methods: {
			open(projectId) {
				this.dialog = true;
				this.resetForm();
				this.conversation.projectId = projectId;
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			resetForm() {
				this.$nextTick(() => {
					this.$refs.formRef.reset();
				});
			},
			async agree() {
				try {
					const isValidated = this.$refs.formRef.validate();
					if (isValidated) {
						this.creating = true;
						const record = await api.createConversation(this.conversation);
						this.resolve(record);
						this.resetForm();
						this.dialog = false;
						this.creating = false;
					}
				} catch (e) {
					if (e.message.includes("already exists")) {
						this.$root.$dangerzone.open("Error", e.message, "OK");
					} else {
						alert(e.message);
					}
				} finally {
					this.creating = false;
				}
			},
			cancel() {
				this.resetForm();
				this.dialog = false;
				this.resolve(false);
			},
			createButtonDisabled() {
				return this.creating || !this.$refs.formRef?.validate();
			},
		},
	};
</script>

<style scoped>
	.text-h7 {
		color: rgba(0, 0, 0, 0.87);
	}
</style>
