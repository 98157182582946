<template>
    <v-dialog
      persistent
      width="auto"
      v-model="dialog"
    >
      <v-card>
        <v-card-title class="text-h5">
          Your Subscription Is Not Active
        </v-card-title>
        <div v-if="isSubscriptionOwner">
          <v-card-text>Please activate your subscription.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              variant="text"
              @click="$emit('activateSubscription')"
            >
              Activate Subscription
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text>The accout owner must complete the subscription process.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              variant="text"
              @click="$emit('logout')"
            >
              Log out
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'InactiveSubscriptionDialog',
  props: {
    isSubscriptionInactive: Boolean,
    isSubscriptionOwner: Boolean
  },
  data () {
      return {
        dialog: true,
      }
  },
}
</script>
