import {
	ParseError,
	isValidPhoneNumber,
	parsePhoneNumberWithError,
	validatePhoneNumberLength,
} from "libphonenumber-js";

export interface ValidationRule {
	isValid: (v: any) => boolean | string;
}

export interface ValidationConfig {
	[key: string]: {
		rules: Array<ValidationRule>;
	};
}

const isString = (v: string | boolean) => {
	return typeof v === "string";
};

class VuetifyValidation<T extends ValidationConfig> {
	private config: T;
	constructor(config: T) {
		this.config = config;
	}
	validate<T extends ValidationConfig>(field: Extract<keyof T, string>, value: any): boolean | string {
		const rules = this.config[field].rules;
		for (let i = 0, l = rules.length; i < l; i++) {
			const result = rules[i].isValid(value);
			if (isString(result)) return result;
		}
		return true;
	}

	static EmailValidation = (errorTpl: string): ValidationRule => {
		return VuetifyValidation.CustomRegexValidation(
			new RegExp(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			),
			errorTpl
		);
	};

	static RequiredValidation: ValidationRule = {
		isValid: (v: any) => !!v || "This field is required.",
	};

	static RequiredValidationField = (errorTpl: string): ValidationRule => {
		return {
			isValid: (v: any) => (!!v && v.trim().length > 0) || errorTpl,
		};
	};

	static MaxLengthValidation(param: number, errorTpl: string): ValidationRule {
		return {
			isValid: (v: string) => {
				const str = v?.toString();
				return !str || str.length <= param || errorTpl;
			},
		};
	}

	static MinLengthValidation(param: number, errorTpl: string): ValidationRule {
		return {
			isValid: (v: string) => {
				const str = v?.toString();
				return !str || str.length >= param || errorTpl;
			},
		};
	}

	static MaxValueValidation(param: number, errorTpl: string): ValidationRule {
		return {
			isValid: (v: number) => !v || v > param || errorTpl,
		};
	}

	static MinValueValidation(param: number, errorTpl: string): ValidationRule {
		return {
			isValid: (v: number) => !v || v < param || errorTpl,
		};
	}

	static CustomRegexValidation(regex: RegExp, message: string): ValidationRule {
		return {
			isValid: (v: string) => {
				return !v || regex.test(v) || message;
			},
		};
	}

	static CustomFunctionValidation(func: (v: any) => boolean | string): ValidationRule {
		return {
			isValid: func,
		};
	}

	static PhoneNumberValidation = (errorTpl: string): ValidationRule => {
		return {
			isValid: (v: string) => {
				if (!v) {
					return true;
				}
				try {
					const length = validatePhoneNumberLength(v);
					if (length == "TOO_SHORT") {
						return "Phone number is too short.";
					} else if (length == "TOO_LONG") {
						return "Phone number is too long.";
					} else if (length == "INVALID_LENGTH") {
						return "Phone number is not a valid length for your country code.";
					} else if (length == "NOT_A_NUMBER") {
						return errorTpl;
					} else if (length == "INVALID_COUNTRY") {
						return "Phone number is missing a valid country code.";
					}
					if (!isValidPhoneNumber(v)) {
						return errorTpl;
					}
					parsePhoneNumberWithError(v, { extract: false });
					return true;
				} catch (error) {
					if (error instanceof ParseError) {
						if (error.message === "INVALID_COUNTRY") {
							return "Phone number is missing a valid country code.";
						} else if (error.message === "TOO_SHORT") {
							return "Phone number is too short.";
						} else if (error.message === "TOO_LONG") {
							return "Phone number is too long.";
						}
					}
					return errorTpl;
				}
			},
		};
	};

	static NameRule = (errorTpl: string): ValidationRule => {
		return {
			isValid(value: string) {
				if (typeof value !== "string") {
					return errorTpl;
				}
				const regex = /^[a-zA-Z.'\- ]*$/;
				if (!regex.test(value)) {
					return `Please enter your name using alphabets (a-z, A-Z), periods (.), apostrophes ('), hyphens/dashes (-), and spaces.`;
				}
				if (value.trim().indexOf(" ") === -1) {
					return errorTpl;
				}
				return true;
			},
		};
	};

	static isValid = (value: string, rules: ValidationRule[]): boolean | string => {
		for (let i = 0; i < rules.length; i++) {
			const result = rules[i].isValid(value);
			if (isString(result)) return result;
		}
		return true;
	};

	static EmailPhoneValidation = (errorTpl: string): ValidationRule => {
		return {
			isValid(values: string[]) {
				if (values.length > 0) {
					return true;
				}
				return errorTpl;
			},
		};
	};

	static DecimalNumberValidation = (errorTpl: string): ValidationRule => {
		return VuetifyValidation.CustomRegexValidation(new RegExp(/^\d*\.?\d{0,2}$/), errorTpl)
	};

	static NumberRequiredValidationField = (errorTpl: string): ValidationRule => {
		return {
			isValid: (v: any) => {
			if (v.length == 0){
				return false || errorTpl
			}
				return true
			}
		}
	}
}
export { VuetifyValidation };
