// Iterate over each character in a string, and add up the number of characters in each one.
// For regular letters, numbers, and special symbols, each character will count as 1. But for
// emoji which are composed of multiple unicode characters, each character will count as >1.
// For example, 🚚 = 2 characters, 👩‍🎓 = 5 characters, and 👨‍👩‍👧‍👦 = 11 characters
String.prototype.unicodeLength = function () {
  let count = 0
  for(let i = 0; i < this.length; i++) {
    count += this.charAt(i).length
  }
  return count
}