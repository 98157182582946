export enum Role {
	Administrator = "Administrator",
	User = "User",
	Support = "Support",
	SuperAdministrator = "Super Administrator",
	ViewOnly = "View Only",
}

export function isRoleOrInRoles(value: string, roles: Role[] | Role): boolean {
	// Convert value to lowercase
	const lowerCasedValue = value.toLowerCase();

	// Handle the case where roles is an array
	if (Array.isArray(roles)) {
		// Convert each role to lowercase and check if it includes the value
		return roles.map((role) => role.toLowerCase()).includes(lowerCasedValue);
	}

	// Handle the case where roles is a single Role
	return lowerCasedValue === roles.toLowerCase();
}
