<template>
	<div>
		<v-menu v-model="menu" :close-on-content-click="false" offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-chip :disabled="disabled" v-bind="attrs" v-on="on" @click="menu = !menu">
					{{ getSelectedLabel }}
					<v-icon>{{ icon }}</v-icon>
				</v-chip>
			</template>
			<v-list>
				<v-list-item
					:disabled="checkItemDisabled(item)"
					v-for="item in itemsWithRemoveOption"
					:key="item.value"
					@click="selectItem(item.value)">
					<v-list-item-title :class="{ 'font-red': item.value === REMOVE_ACCESS.value }"
						>{{ item.label }}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
	import { AccessType } from "@/enums/AccessType";
	import { constants } from "@/api/constants";
	export default {
		props: {
			value: {
				type: String,
				required: true,
			},
			editDisabled: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			items: {
				type: Array,
				default: () =>
					Object.keys(AccessType).map((key) => ({
						label: key,
						value: AccessType[key],
					})),
			},
		},
		data() {
			return {
				menu: false,
				REMOVE_ACCESS: constants.REMOVE_ACCESS,
				AccessType,
			};
		},
		computed: {
			getSelectedLabel() {
				const selectedItem = this.items.find((item) => item.value === this.value);
				return selectedItem ? selectedItem.label : "";
			},
			itemsWithRemoveOption() {
				return [...this.items, this.REMOVE_ACCESS];
			},
			icon() {
				if (this.disabled) {
					return "";
				}
				return this.menu ? "icon-chevron-up" : "icon-chevron-down";
			},
		},
		methods: {
			checkItemDisabled(item) {
				return this.editDisabled && item.value !== this.REMOVE_ACCESS.value && item.value !== AccessType.Viewer;
			},
			selectItem(value) {
				if (value !== this.value) {
					this.$emit("change", value);
				}
				this.menu = false;
			},
		},
	};
</script>
<style lang="css" scoped>
	.font-red {
		color: red;
	}
</style>
