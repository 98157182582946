<template>
	<v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
		<v-card>
			<v-card-title :style="{ 'word-break': 'normal' }">
				{{ title }}
			</v-card-title>
			<v-card-text v-show="!!message" v-html="message"></v-card-text>
			<v-card-text v-show="requireDeleteConfirmation">
				<v-text-field v-model="deleteConfirmationText" label="Type DELETE to confirm"></v-text-field>
			</v-card-text>

			<v-card-actions v-if="!switchButtonStyles">
				<v-spacer></v-spacer>
				<v-btn
					id="confirm-action-btn"
					color="grey"
					text
					@click.native="agree"
					:disabled="disableConfirmationButton"
					>{{ agreeText }}</v-btn
				>
				<v-btn id="cancel-action-btn" color="primary" @click.native="cancel">{{ cancelText }}</v-btn>
			</v-card-actions>
			<v-card-actions v-else>
				<v-spacer></v-spacer>
				<v-btn id="cancel-action-btn" color="grey" text @click.native="cancel">{{ cancelText }}</v-btn>
				<v-btn
					id="confirm-action-btn"
					color="primary"
					@click.native="agree"
					:disabled="disableConfirmationButton"
					>{{ agreeText }}</v-btn
				>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	/**
	 * Vuetify Confirm Dialog component
	 *
	 * Insert component where you want to use it:
	 * <confirm ref="confirm"></confirm>
	 *
	 * Call it:
	 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
	 * Or use await:
	 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
	 *   // yes
	 * }
	 * else {
	 *   // cancel
	 * }
	 *
	 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
	 * <template>
	 *   <v-app>
	 *     ...
	 *     <confirm ref="confirm"></confirm>
	 *   </v-app>
	 * </template>
	 *
	 * mounted() {
	 *   this.$root.$confirm = this.$refs.confirm.open
	 * }
	 */
	export default {
		data: () => ({
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			requireDeleteConfirmation: false,
			deleteConfirmationText: null,
			title: null,
			switchButtonStyles: false,
			agreeText: "Yes",
			cancelText: "No",
			options: {
				color: "primary",
				width: 440,
				zIndex: 200,
			},
		}),
		methods: {
			open(title, message, options, requireDeleteConfirmation, agreeText, cancelText, switchButtonStyles) {
				this.dialog = true;
				this.title = title || "Are you sure?";
				this.agreeText = agreeText || "Yes";
				this.cancelText = cancelText || "No";
				this.switchButtonStyles = switchButtonStyles || false;
				this.message = message || "You can't undo this. Are you sure you want to continue?";
				this.options = Object.assign(this.options, options);
				console.log(this.options, agreeText, cancelText, switchButtonStyles);
				this.requireDeleteConfirmation = requireDeleteConfirmation;
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			agree() {
				this.resolve(true);
				this.deleteConfirmationText = null;
				this.dialog = false;
			},
			cancel() {
				this.resolve(false);
				this.deleteConfirmationText = null;
				this.dialog = false;
			},
		},
		computed: {
			disableConfirmationButton() {
				return this.requireDeleteConfirmation && this.deleteConfirmationText != "DELETE";
			},
		},
	};
</script>
