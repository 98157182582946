import * as Sentry from "@sentry/vue";
import {
	ReportingObserver as reportingObserverIntegration,
	ExtraErrorData as extraErrorDataIntegration,
	CaptureConsole as captureConsoleIntegration,
	HttpClient as httpClientIntegration,
} from "@sentry/integrations";

import Vue from "vue";
import router from "../router";

const sentry = {
	init() {
		// NOTE: For this to work with Sentry, you must increment your Package JSON file to the release.
		// ex. "version": "0.2.45" for release 2.0 - R45.
		const release = APP_VERSION;
		const name = APP_NAME;
		const ignoreCodes = [401, 404];
		const config = {
			Vue,
			environment: window.env.ENVIRONMENT,
			release: `${name}@${release}`,
			dsn: window.env.VITE_SENTRY_DSN,
			attachStacktrace: true,
			maxBreadcrumbs: 50,
			beforeSend: (event, hint) => {
				// see all errors - handy if you want to always log stuff
				// console.log(hint.originalException)

				// don't send when error code 401
				const response = hint.originalException?.response;
				if (response && response.status && ignoreCodes.indexOf(response.status) > -1) {
					return null;
				}
				return event;
			},
			integrations: [
				new Sentry.BrowserTracing({
					routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				}),
				new captureConsoleIntegration({
					levels: ["error"],
				}),
				new extraErrorDataIntegration({
					depth: 3,
				}),
				new httpClientIntegration({
					failedRequestStatusCodes: [
						[400, 499],
						[500, 599],
					],
				}),
				new reportingObserverIntegration(),
				new Sentry.Replay({
					maskAllText: false, //Mask all text content. Will pass text content through maskFn before sending to server.
					blockAllMedia: true, //Block all media elements (img, svg, video, object, picture, embed, map, audio).
					maskAllInputs: false, //	Mask values of <input> elements. Passes input values through maskFn before sending to server.
				}),
			],
			sendDefaultPii: true,
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: parseFloat(window.env.VITE_SENTRY_TRACES_SAMPLE_RATE, 10),

			// This sets the sample rate to be 10%. You may want this to be 100% while
			// in development and sample at a lower rate in production
			replaysSessionSampleRate: parseFloat(window.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE, 10),
			// If the entire session is not sampled, use the below sample rate to sample
			// sessions when an error occurs.
			replaysOnErrorSampleRate: 1.0,
			/*
      The default value of tracePropagationTargets is ['localhost', /^\//]. 
      This means that by default, tracingThe process of logging the events that took place during a request, often across multiple services.
      headers are only attached to requests that contain localhost in their URL or requests whose URL starts with a '/' (for example GET /api/v1/users).
      */
			// In short this will give you tracing of errors between Contact -> API and Contact -> Socket -> API.
			tracePropagationTargets: [
				"localhost",
				/^https:\/\/api\.testing\.liveswitch\.com/,
				/^https:\/\/api\.contact\.testing\.liveswitch\.com/,
				/^https:\/\/api\.contact\.staging\.liveswitch\.com/,
				/^https:\/\/api\.staging\.liveswitch\.com/,
				/^https:\/\/api\.demo\.liveswitch\.com/,
				/^https:\/\/api\.contact\.demo\.liveswitch\.com/,
				/^https:\/\/api\.contact\.liveswitch\.com/,
				/^https:\/\/api\.production\.liveswitch\.com/,
				/^https:\/\/api\.liveswitch\.com/,
				/^https:\/\/api\.supbubble\.com/,
				/^https:\/\/jake-api\.ngrok\.app/,
				/^https:\/\/jake-socket\.ngrok\.app/,
				/^https:\/\/ls-concierge-socket-staging\.herokuapp\.com/,
				/^https:\/\/ls-concierge-socket-develop\.herokuapp\.com/,
				/^https:\/\/socket\.concierge\.liveswitch\.com/,
			],
		};
		Sentry.init(config);
	},
};
export default {
	install: function (Vue) {
		Vue.prototype.$sentry = sentry;
		if (window.location.search.indexOf("no-sentry") > -1) {
			return;
		}
		if (window.env.ENVIRONMENT == "development") {
			// bail out on dev env.
			return;
		}

		sentry.init();

		if (Vue.prototype.$auth.user) {
			console.log(Vue.prototype.$auth.user.email);
			Sentry.setUser({
				// TODO: segment: {company}
				email: Vue.prototype.$auth.user.email,
			});
		}
	},
};
