<template>
	<v-snackbar
		content-class="pt-2 px-4"
		v-model="isLinkCopied"
		top
		light
		:timeout="-1"
		:multi-line="true"
		:vertical="true">
		<div class="share-text">{{ shareText }}</div>
		<div class="mt-4" style="font-size: 16px; word-break: break-all; display: flex">
			<v-tooltip v-model="linkCopiedBubble" bottom content-class="copied-bubble" style="z-index: 9999">
				<template v-slot:activator="{}">
					<v-btn
						elevation="0"
						:theme="linkCopiedBubble ? '' : 'dark'"
						class="copy-recording-link"
						:class="linkCopiedBubble ? 'copied' : ''"
						@click="copyLinkBubble">
						<i class="icon-link-double"></i>
					</v-btn>
				</template>
				<span>Copied!</span>
			</v-tooltip>
			<div class="share-url">{{ shareUrl }}</div>
		</div>

		<template v-slot:action="{ attrs }">
			<div class="pb-2">
				<v-btn
					style="font-size: 14px; border: 1px solid #0070ff; margin-right: 10px"
					color="#0070ff"
					text
					v-bind="attrs"
					@click="closeDialog">
					Close
				</v-btn>
			</div>
		</template>
	</v-snackbar>
</template>

<script>
	export default {
		props: {
			linkCopied: {
				type: Boolean,
				default: false,
			},
			shareText: {
				type: String,
				default: "Share this link with others to collaborate on this project.",
			},
			shareUrl: {
				type: String,
				default: "",
			},
			closeDialog: {
				type: Function,
				default: () => {},
			},
		},
		mounted() {
			this.isLinkCopied = this.linkCopied;
		},
		data() {
			return {
				linkCopiedBubble: false,
				isLinkCopied: false,
			};
		},
		methods: {
			copyLinkBubble() {
				this.linkCopiedBubble = true;
				setTimeout(() => {
					this.linkCopiedBubble = false;
				}, 2000);
			},
		},
		watch: {
			linkCopied(newVal) {
				this.isLinkCopied = newVal;
			},
		},
	};
</script>

<style scoped>
	.background-white {
		background-color: #fff !important;
	}

	.copied-bubble {
		background-color: #fff;
		color: #000;
		transform: translateX(10%) !important;
	}

	.copied {
		background-color: #d4e7ff !important;
		border-color: #d4e7ff;
	}

	.copy-recording-link.v-btn {
		width: 40px;
		height: 40px;
		min-width: 40px;
		padding: 0;
		border: 1px solid #fff;
		margin-right: 10px;
		background-color: black;
	}

	.copy-recording-link.v-btn.copied i {
		color: #0070ff;
	}

	.copy-recording-link.v-btn i {
		color: white;
	}

	.share-url {
		font-weight: 600 !important;
		color: #0070ff !important;
	}

	.share-text {
		font-weight: 600 !important;
		font-family: Inter Tight !important;
		color: #03011d99 !important;
	}
	::v-deep .v-sheet.v-snack__wrapper {
		border-radius: 12px !important;
	}
</style>
