<template>
  <v-dialog v-model="visible" @keydown.esc="close" width="unset">
    <v-card>

      <!--Card title-->
      <v-card-title style="word-break:normal">
        Copy/paste the following script into your website
      </v-card-title>

      <!--Card text-->
      <v-card-text class="pa-4">

        <!--Embeddable script-->
        <div style="border:1px solid #ccc;padding:10px;white-space:pre-line">
          &lt;script&gt;<br>
          (function(i,s,o,g,r,a,m){<br>
          i[r]=i[r] || function(){ (i[r].q = i[r].q || []).push(arguments)}<br>
          i[r].l=new Date().getTime();a=s.createElement(o);a.async=1;a.src=g;<br>
          m = s.getElementsByTagName(o)[0]<br>
          if(m){m.parentNode.insertBefore(a,m)}else{document.body.appendChild(a)}<br>
          })(window,document,'script','//bubble.supbubble.com/bubble.js','sb');<br>
          sb('init', {id: '{{ websiteCodename }}'})<br>
          &lt;/script&gt;
        </div>

        <!--Link to Wix-->
        <p style="padding-top:10px;margin-bottom:0">Certain platforms have special instructions:</p>
        <ul>
          <li><a target="_blank" href="https://support.wix.com/en/article/embedding-custom-code-to-your-site#adding-custom-code-to-your-site">Wix</a></li>
        </ul>
      </v-card-text>

      <!--Close-->
      <v-card-actions class="pt-0">
        <v-spacer/>
        <v-btn color="primary" text @click="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    resolve: null,
    reject: null,
    websiteCodename: null,
    visible: false,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 200
    }
  }),
  methods: {
    open(websiteCodename, options) {
      this.websiteCodename = websiteCodename
      this.options = Object.assign(this.options, options)
      this.visible = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close() {
      this.resolve()
      this.visible = false
      this.websiteCodename = null
    }
  }
}
</script>