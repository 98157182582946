<template>
	<figure
		:id="videoContainerId"
		class="video-container"
		:class="{
			fullscreen: isFullScreen,
			playing: isPlaying,
			paused: !isPlaying,
			scrubbing: isScrubbing,
			wasPlaying: wasPlaying,
			waiting: waiting,
		}"
		@click="play">
		<v-icon class="pause-indicator" v-show="!isPlaying">{{ isPlaying ? "icon-pause" : "icon-play-solid" }}</v-icon>
		<v-progress-circular
			class="loading-indicator"
			v-show="waiting"
			indeterminate
			size="100"
			width="7"
			color="primary">
		</v-progress-circular>
		<video
			controls
			width="100%"
			height="300px"
			:id="videoId"
			:src="source"
			class="conversation-recording"
			poster="@/assets/lsc-poster.svg"
			preload="metadata"
			crossOrigin="anonymous"
			webkit-playsinline
			playsinline></video>
		<div
			id="video-controls"
			class="video-controls"
			:class="{
				active: areCustomControlSupported,
			}">
			<div class="video-slider">
				<v-slider
					v-model="percent"
					color="white"
					track-color="#FFFFFF52"
					thumb-color="white"
					id="seek-slider"
					min="0"
					:max="maxSliderValue"
					:thumb-size="48"
					:class="{ scrubbing: isScrubbing }"
					@click="sliderClicked"
					@input="videoScrubbed">
				</v-slider>
			</div>
			<div class="video-buttons" @click="controlsClicked">
				<v-btn id="play" icon :data-state="!isPlaying ? 'play' : 'pause'" @click="play">
					<v-icon>{{ isPlaying ? "icon-pause" : "icon-play-solid" }}</v-icon>
				</v-btn>
				<div class="progress">
					<span class="current-time">
						{{ toHoursAndMinutes(video.currentTime) }}
					</span>
					/
					<span class="total-time">
						{{ toHoursAndMinutes(duration) }}
					</span>
				</div>
				<v-btn id="mute" icon :data-state="isMuted ? 'unmute' : 'mute'" @click="mute">
					<v-icon>{{ isMuted ? "icon-volume-slash-solid" : "icon-volume-solid" }}</v-icon>
				</v-btn>
				<v-btn id="download" icon :disabled="isDownloading" @click="download">
					<v-icon v-if="!isDownloading">icon-download</v-icon>
					<v-progress-circular
						v-else
						class="loading"
						color="white"
						:size="20"
						indeterminate></v-progress-circular>
				</v-btn>
				<v-btn
					v-if="canSnapshot"
					id="snapshot"
					icon
					:disabled="video.currentTime == 0"
					@click="$emit('take-snapshot')">
					<v-icon>icon-snapshot</v-icon>
				</v-btn>
				<v-btn id="fullscreen" icon @click="toggleFullscreen">
					<v-icon>{{ isFullScreen ? "icon-fullscreen-exit" : "icon-fullscreen" }}</v-icon>
				</v-btn>
				<v-menu top left :attach="`#${videoContainerId}`" v-model="showMoreControls">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							:id="`more-controls-btn-${videoId}`"
							icon
							v-bind="attrs"
							v-on="on"
							@click="showMoreControls = true">
							<v-icon>icon-dots-vertical-more</v-icon>
						</v-btn>
					</template>

					<v-list class="more-controls">
						<v-list-item v-if="canPictureInPicture">
							<v-list-item-title @click="startPictureInPicture"
								><i class="icon-picture-in-picture" /> Picture in picture</v-list-item-title
							>
						</v-list-item>
						<v-list-item>
							<v-menu
								top
								right
								:nudge-left="140"
								:max-height="300"
								:attach="`#more-controls-btn-${videoId}`"
								v-model="showSpeedControls">
								<template v-slot:activator="{ on, attrs }">
									<v-list-item-title
										v-bind="attrs"
										v-on="on"
										@click="
											showSpeedControls = true;
											showMoreControls = false;
										"
										><i class="icon-playback-speed" /> Playback speed</v-list-item-title
									>
								</template>

								<v-list class="speed-list">
									<v-list-item>
										<v-list-item-title @click="goBackToMoreControls"
											><i class="icon-chevron-left" /> Options</v-list-item-title
										>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.25 }">
										<v-list-item-title @click="changePlaybackSpeed(0.25)">0.25</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.5 }">
										<v-list-item-title @click="changePlaybackSpeed(0.5)">0.5</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 0.75 }">
										<v-list-item-title @click="changePlaybackSpeed(0.75)">0.75</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1 }">
										<v-list-item-title @click="changePlaybackSpeed(1)">Normal</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.25 }">
										<v-list-item-title @click="changePlaybackSpeed(1.25)">1.25</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.5 }">
										<v-list-item-title @click="changePlaybackSpeed(1.5)">1.5</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 1.75 }">
										<v-list-item-title @click="changePlaybackSpeed(1.75)">1.75</v-list-item-title>
									</v-list-item>
									<v-list-item :class="{ active: currentPlaybackSpeed == 2 }">
										<v-list-item-title @click="changePlaybackSpeed(2)">2</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>
	</figure>
</template>

<script>
	import DeviceUtility from "@/helpers/device";

	export default {
		name: "VideoPlayer",
		components: {},
		props: {
			canSnapshot: Boolean,
			filename: String,
			recordingId: String,
			source: String,
			videoId: String,
			disablePlayback: Boolean,
		},
		data: () => ({
			areCustomControlSupported: false,
			canPictureInPicture: true,
			canPlay: false,
			currentPlaybackSpeed: 1,
			duration: 0,
			figureId: null,
			isDownloading: false,
			isFullScreen: false,
			isMuted: false,
			isPlaying: false,
			isScrubbing: false,
			maxSliderValue: 100,
			percent: 0,
			playPromise: undefined,
			progress: 0,
			showMoreControls: false,
			showSpeedControls: false,
			wasPlaying: false,
			waiting: false,
			video: {
				muted: false,
				paused: false,
				ended: false,
				currentTime: 0,
			},
			videoContainer: null,
		}),
		mounted() {
			const supportsVideo = !!document.createElement("video").canPlayType;
			if (supportsVideo) {
				// set up custom controls
				this.areCustomControlSupported = true;
				this.videoContainer = document.getElementById(this.videoContainerId);
				this.video = document.getElementById(this.videoId);

				//hide default controls
				this.video.controls = false;
				this.video.load();

				if (!("pictureInPictureEnabled" in document)) {
					this.canPictureInPicture = false;
				}

				this.video.addEventListener("loadedmetadata", () => {
					this.canPlay = true;
					this.duration = this.video.duration;
					if (this.duration > 180) {
						this.maxSliderValue = 1000;
					}
				});

				this.video.addEventListener("progress", () => {
					// todo: can we use this to display the second loader bar?
					// console.log(`progress`);
				});

				this.video.addEventListener("canplay", () => {
					// console.log(`can play ${this.videoId}`);
					this.canPlay = true;
					if (this.waiting) {
						this.playPromise = this.video.play();
						this.waiting = false;
					}
				});

				this.video.addEventListener("waiting", () => {
					// wait a specified time before setting wait state as the waiting event fires every time you seek in the video, and we dont want to show an indicator each time
					setTimeout(() => {
						// if video now has enough data to play, ignore the event
						// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
						if (this.video.readyState >= HTMLMediaElement.HAVE_FUTURE_DATA) return; 
						this.canPlay = false;
						this.waiting = true;
					}, 1000);
				});

				this.video.addEventListener("play", () => {
					this.isPlaying = true;
					this.waiting = false;
					if (this.playPromise !== undefined) {
						this.playPromise = undefined;
					}
				});

				this.video.addEventListener("pause", () => {
					this.isPlaying = false;
				});

				this.video.addEventListener("timeupdate", () => {
					// video current time in percentage
					this.percent = Math.floor((this.video.currentTime / this.duration) * this.maxSliderValue);
				});

				document.addEventListener("fullscreenchange", () => {
					if (!document.fullscreenElement) {
						this.isFullScreen = false;
					}
				});
				this.video.addEventListener("webkitendfullscreen", () => {
					if (
						document.fullScreenElement ||
						document.webkitIsFullScreen == true ||
						document.mozFullScreen ||
						document.msFullscreenElement
					) {
						this.isFullScreen = true;
					} else {
						this.isFullScreen = false;
					}
				});
			}
		},
		computed: {
			videoContainerId: function () {
				return `video-${this.recordingId}-container`;
			},
		},
		methods: {
			changePlaybackSpeed(speed) {
				this.video.playbackRate = speed;
				this.currentPlaybackSpeed = speed;
				window.setTimeout(() => {
					this.showSpeedControls = false;
					this.showMoreControls = false;
					console.log("hiding speed controls, hiding more controls");
				}, 0);
			},
			controlsClicked(e) {
				e.stopPropagation();
				e.preventDefault();
			},
			async download(e) {
				e.preventDefault();
				const me = this;
				this.isDownloading = true;
				const filename = this.filename;
				var xhr = new XMLHttpRequest();
				xhr.open("GET", this.video.src, true);
				xhr.responseType = "blob";
				xhr.onload = function () {
					var urlCreator = window.URL || window.webkitURL;
					var videoUrl = urlCreator.createObjectURL(this.response);
					var tag = document.createElement("a");
					tag.href = videoUrl;
					tag.target = "_blank";
					tag.download = filename;
					document.body.appendChild(tag);
					tag.click();
					document.body.removeChild(tag);
					me.isDownloading = false;
				};
				xhr.onerror = (err) => {
					me.isDownloading = false;
					console.log("Error download video", err);
				};
				xhr.send();
			},
			goBackToMoreControls() {
				window.setTimeout(() => {
					this.showSpeedControls = false;
					this.showMoreControls = true;
					console.log("hiding speed controls, showing more controls");
				}, 0);
			},
			mute() {
				this.isMuted = !this.isMuted;
				this.video.muted = !this.video.muted;
			},
			async play() {
				if (!this.canPlay) {
					console.warn("Tried playing video before canPlay is set.");
					return;
				}

				const isPlaying =
					this.video.currentTime > 0 &&
					!this.video.paused &&
					!this.video.ended &&
					this.video.readyState > this.video.HAVE_CURRENT_DATA;

				if (!isPlaying) {
					console.log("this play");
					this.playPromise = this.video.play();
				} else {
					await this.pause();
				}
			},
			async pause() {
				if (this.playPromise !== undefined) {
					await this.playPromise;
					this.video.pause();
				} else {
					this.video.pause();
				}
			},
			setFullscreenData(state) {
				this.videoContainer.setAttribute("data-fullscreen", !!state);
			},
			sliderClicked(e) {
				e.preventDefault();
				e.stopPropagation();
			},
			async startPictureInPicture() {
				try {
					if (this.video !== document.pictureInPictureElement) {
						await this.video.requestPictureInPicture();
					}
					window.setTimeout(() => {
						this.showSpeedControls = false;
						this.showMoreControls = false;
						console.log("hiding speed controls, hiding more controls");
					}, 0);
				} catch (error) {
					console.error(error);
				}
			},
			async toggleFullscreen() {
				if (
					document.fullscreenElement ||
					document.webkitFullscreenElement ||
					document.mozFullScreenElement ||
					document.webkitCurrentFullScreenElement
				) {
					// The document is in fullscreen mode
					// document.exitFullscreen();
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.mozCancelFullScreen) {
						/* Firefox */
						document.mozCancelFullScreen();
					} else if (document.webkitExitFullscreen) {
						/* Chrome, Safari and Opera */
						document.webkitExitFullscreen();
					} else if (document.msExitFullscreen) {
						/* IE/Edge */
						document.msExitFullscreen();
					}
					this.setFullscreenData(false);
					this.isFullScreen = false;
				} else {
					// The document is not in fullscreen mode

					let timeout = 1;

					// Workaround for iOS issue. Start playing the video prior to requesting full screen.
					if (DeviceUtility.isIOS) {
						await this.play();
						timeout = 200;
					}

					setTimeout(() => {
						if (this.videoContainer.requestFullscreen) {
							this.videoContainer.requestFullscreen();
						} else if (this.videoContainer.mozRequestFullScreen) {
							/* Firefox */
							this.videoContainer.mozRequestFullScreen();
						} else if (this.videoContainer.webkitRequestFullscreen) {
							/* Chrome, Safari and Opera */
							this.videoContainer.webkitRequestFullscreen();
						} else if (this.video.webkitEnterFullScreen) {
							this.video.webkitEnterFullScreen();
						} else if (this.videoContainer.msRequestFullscreen) {
							/* IE/Edge */
							this.videoContainer.msRequestFullscreen();
						}
						this.setFullscreenData(true);
						this.isFullScreen = true;
					}, timeout);
				}
			},
			toHoursAndMinutes(totalSeconds) {
				const totalMinutes = Math.floor(totalSeconds / 60);
				const seconds = (totalSeconds % 60).toFixed(0).toString();
				const hours = Math.floor(totalMinutes / 60).toString();
				const minutes = (totalMinutes % 60).toString();
				if (hours > 0) {
					return `${hours}:${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
				}
				return `${minutes}:${seconds.padStart(2, "0")}`;
			},
			async videoScrubbed(value) {
				//turn off transition time
				this.wasPlaying = !this.video.paused;
				this.isScrubbing = true;
				if (!this.waiting) {
					await this.pause();
				}
				this.video.currentTime = (value / this.maxSliderValue) * this.duration;
				window.setTimeout(() => {
					if (this.wasPlaying && !this.waiting) {
						this.playPromise = this.video.play();
					}
					this.wasPlaying = false;
					this.isScrubbing = false;
				}, 100);
			},
		},
	};
</script>

<style scoped>
	#video-container {
		/* height: 300px; */
	}

	.video-container {
		position: relative;
		color: #fff;
		background-color: #000;
		/* height: 300px; */
	}

	.pause-indicator {
		background-color: #00000066;
		border-radius: 100px;
		position: absolute;
		top: calc(50% - 50px);
		left: calc(50% - 50px);
		width: 100px;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 4rem !important;
	}

	.loading-indicator {
		position: absolute;
		top: calc(50% - 50px);
		left: calc(50% - 50px);
	}

	.video-container.paused.scrubbing.wasPlaying::after {
		content: "";
		background: none;
	}

	.video-container.waiting .loading {
		position: absolute;
		top: calc(50% - 25px);
		left: calc(50% - 25px);
		color: gray;
		z-index: 1;
	}

	.video-container.fullscreen {
		height: 100%;
	}

	.video-container.fullscreen video {
		height: 100%;
	}

	.video-container i {
		font-size: 18px;
		color: #fff;
	}

	.video-container.fullscreen .video-controls {
		position: absolute;
	}

	.video-controls {
		display: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0 8px 8px 8px;
		background: rgb(0, 0, 0);
		background: -moz-linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		background: -webkit-linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0) 75%,
			rgba(0, 0, 0, 0.5) 90%,
			rgba(0, 0, 0, 1) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#000000",GradientType=1);
	}

	.video-controls.active {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.video-controls::v-deep > .video-slider .v-slider__thumb-container,
	.video-controls::v-deep > .video-slider .v-slider__track-background,
	.video-controls::v-deep > .video-slider .v-slider__track-fill {
		transition: 0.3s linear;
	}

	.video-controls::v-deep > .video-slider > .scrubbing .v-slider__thumb-container,
	.video-controls::v-deep > .video-slider > .scrubbing .v-slider__track-background,
	.video-controls::v-deep > .video-slider > .scrubbing .v-slider__track-fill {
		transition: 0s;
	}

	.video-controls::v-deep > .video-slider .v-slider--horizontal {
		margin-left: 18px;
		margin-right: 18px;
	}

	.video-controls::v-deep > .video-slider .v-slider--horizontal .v-slider__track-container {
		height: 6px;
		border-radius: 5px;
	}

	.video-controls::v-deep > .video-slider .v-slider--horizontal .v-slider__thumb {
		width: 18px;
		height: 18px;
		left: -9px;
	}

	.video-controls::v-deep > .video-slider .v-slider--horizontal .v-slider__thumb:before {
		width: 32px;
		height: 32px;
		transform: scale(0);
		left: -7px;
		top: -7px;
	}

	.video-controls::v-deep
		> .video-slider
		.v-slider--horizontal
		.v-slider__thumb-container:hover
		.v-slider__thumb:before {
		transform: scale(1);
	}

	.video-controls::v-deep > .video-slider .v-input__slider .v-input__slot {
		margin-bottom: 0px;
	}

	.video-controls::v-deep > .video-slider .v-input__slider .v-messages {
		display: none;
	}

	.video-controls::v-deep > .video-slider .v-slider__track-container .v-slider__track-background {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.video-controls::v-deep > .video-slider .v-slider__track-container .v-slider__track-fill {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}

	.video-buttons {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.video-buttons > div.progress {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #ffffff99;
		font-family: "Inter";
		font-weight: 400;
	}

	.video-buttons > div.progress > .current-time {
		margin-right: 5px;
		color: #ffffff;
		font-weight: 600;
	}

	.video-buttons > div.progress > .total-time {
		margin-left: 5px;
	}

	.video-buttons > #snapshot:disabled i,
	.video-buttons > #snapshot[disabled] i {
		color: #cecece !important;
	}

	.video-buttons > .v-btn {
		background: none;
		color: #fff;
	}

	.more-controls::v-deep .v-list-item,
	.speed-list::v-deep .v-list-item {
		cursor: pointer;
	}

	.more-controls::v-deep i,
	.speed-list::v-deep i {
		color: #000;
	}

	.more-controls::v-deep .v-list-item:hover,
	.speed-list::v-deep .v-list-item:hover {
		background-color: #00000011;
	}

	.speed-list::v-deep .v-list-item.active {
		border-left-color: #0070ff;
		border-left-style: solid;
		border-left-width: 5px;
	}

	.speed-list::v-deep .v-list-item:not(:first-child) .v-list-item__title {
		text-align: center;
	}

	@media (max-width: 415px) {
		.v-btn--icon.v-size--default {
			width: 32px;
			height: 32px;
		}
	}

	@media (max-width: 959px) {
		.v-btn--icon.v-size--default {
			width: 24px;
			height: 24px;
			margin: 0 1px;
		}

		.v-btn--icon.v-size--default .v-icon {
			font-size: 1.25rem;
		}
	}
</style>
