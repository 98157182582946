<template>
  <v-dialog v-model="visible" width="500" content-class="overflow-hidden" persistent>
    <v-row no-gutters>
      <v-col>
        <v-card
          elevation="2"
          outlined
          shaped
          tile
        >
          <v-card-title>Account Not Found</v-card-title>
          <v-card-text>We're sorry, but there is no LiveSwitch account associated with this email.  Please try logging in with different credentials, or contact the account administrator for your organization.  You can also reach the LiveSwitch team at {{ supportEmail }}, and we'd be happy to assist.</v-card-text>
          <v-card-actions>
            <a href="#" @click="logout"><v-icon>icon-door-open</v-icon>Try again with different email and password.</a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
  export default {
    name: 'NoAccount',
    data () {
      return {
        visible: false,
        supportEmail: window.env.VITE_SUPPORT_EMAIL
      }
    },
    methods: {
      open() {
        this.visible = true;
      },
      logout(){
        this.$auth.logout({
          returnTo: window.location.protocol + "//" + window.location.host + "/logout",
        });
      },
    },
  }
</script>

<style scoped>
.overflow-hidden {
  overflow: hidden !important;
}
</style>