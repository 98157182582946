<template>
	<!-- z-index is at 10010 because the lightbox and cropper tools added have their z-index at 9999
    so we have to go above it to ensure the modal appears even when they are being shown -->
	<v-overlay z-index="10010" :value="dialog">
		<v-snackbar v-model="dialog" color="error" :multi-line="true" timeout="-1" :centered="true" class="danger-zone">
			<div>
				<h3>
					{{ title }}
					<v-btn icon @click="cancel">
						<v-icon>icon-close-medium</v-icon>
					</v-btn>
				</h3>
				<p>
					<span class="danger-message" v-show="!!message">{{ message }}</span>
				</p>
				<p v-if="contactEmailFlag">
					<span
						>We are sorry you've encountered an error! It has been automatically reported, but if you'd like
						some additional help, you can</span
					>

					<a :href="contactEmailProperty" target="_blank"> email support. </a>
				</p>
				<v-btn elevation="0" color="error" @click.native="agree">{{ agreeText }}</v-btn>
			</div>
		</v-snackbar>
	</v-overlay>
</template>

<script>
	export default {
		data: () => ({
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			title: null,
			agreeText: "",
			options: {
				color: "error",
				width: 440,
				zIndex: 200,
			},
			contactEmail: window.env.VITE_SUPPORT_EMAIL,
			contactEmailFlag: false,
		}),
		methods: {
			open(title, message, agreeText, contactEmailFlag) {
				this.dialog = true;
				this.title = title || "Danger Zone";
				this.message = message || "You can't undo this. Are you sure you want to continue?";
				this.agreeText = agreeText || "Delete";
				this.contactEmailFlag = contactEmailFlag || false;
				//this.options = Object.assign(this.options, options)
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			agree() {
				this.resolve(true);
				this.dialog = false;
			},
			cancel() {
				this.resolve(false);
				this.dialog = false;
			},
		},
		computed: {
			contactEmailProperty() {
				return `mailto:${this.contactEmail}`;
			},
		},
	};
</script>

<style scoped>
	.danger-zone::v-deep .v-snack__wrapper {
		border-left-width: 8px;
		border-left-style: solid;
	}

	.danger-zone::v-deep .v-snack__wrapper h3 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 5px;
		font-size: 1.5rem;
	}

	.danger-zone::v-deep .v-snack__wrapper h3 > button {
		margin-top: -10px;
		margin-right: -10px;
		font-size: 12px;
	}

	.danger-zone::v-deep .v-snack__wrapper.error h3 > button {
		color: #e84867 !important;
	}

	.danger-zone::v-deep .v-snack__wrapper.error button.error {
		background-color: #e84867 !important;
		float: right;
	}

	.danger-zone::v-deep .v-snack__wrapper.error {
		border-left-color: #e84867 !important;
		background-color: #faeef0 !important;
		color: #e84867 !important;
	}

	.danger-message {
		word-break: break-word;
	}
</style>
