let dirty = {
	compare: function (newObject, oldObject) {
		let dirty = false;
		for (const prop in newObject) {
			if ((typeof newObject[prop] === "object" || Array.isArray(newObject[prop])) && newObject[prop] !== null) {
				// exclude object and array from this check
				continue;
			}
			let different = oldObject[prop] !== newObject[prop];
			let differentButNotReally =
				(oldObject[prop] === undefined || oldObject[prop] === null) &&
				(newObject[prop] === "" || newObject[prop] === false);
			if (different && !differentButNotReally) {
				dirty = true;
				//console.log(prop, 'Original: ' + oldObject[prop], 'New: ' + newObject[prop])
			}
		}
		return dirty;
	},
	compareExactly: function (newObject, oldObject) {
		return JSON.stringify(newObject) !== JSON.stringify(oldObject);
	},
};

export default dirty;
