export const constants = {
	FEATURES: {
		INTEGRATIONS: "INTEGRATIONS",
		MARKETPLACE: "MARKETPLACE",
		ATTRIBUTES: "ATTRIBUTES",
	},
	CONVERSATION_STATUS: {
		NotStarted: "NotStarted",
		Unread: "Unread",
		Sent: "Sent",
		Delivered: "Delivered",
		InProgress: "InProgress",
		Processing: "Processing",
		ReadyForReview: "ReadyForReview",
		Reviewed: "Reviewed",
		Scheduled: "Scheduled",
	},
	PROJECT_STATUS: {
		NotStarted: "NotStarted",
		InProgress: "InProgress",
		Completed: "Completed",
	},
	CONVERSATION_TYPE: {
		ASYNC: "async",
		SYNC: "sync",
		PHOTOS: "photos",
		UPLOADS: "uploads",
	},
	INITIATION_TYPE: {
		ADMIN: "admin",
		BEACON: "beacon",
		SELF: "self",
	},
	SCHEDULER_STATE: {
		SCHEDULE: "schedule",
		SELECT_DATE: "select-date",
		SELECT_TIME: "select-time",
		CONFIRMATION: "confirmation",
	},
	DIRECTION: {
		INBOUND: "INBOUND",
		OUTBOUND: "OUTBOUND",
	},
	SORT_SESSIONS_VIEW_BY: {
		CREATED_AT: "createdAt",
		READY_FOR_REVIEW: "ReadyForReview",
	},
	REMOVE_ACCESS: {
		label: "Remove Access",
		value: "remove",
	},
	PREMIUM_MESSAGE_ADMIN: "This is a premium feature. Upgrade your subscription to access all features.",
	PREMIUM_MESSAGE_USER: "This is a premium feature. Upgrade your subscription to access all features.",
	areEqual: function (typeName1, typeName2) {
		return typeName1 && typeName2 && typeName1.toUpperCase() == typeName2.toUpperCase();
	},
};
export default constants;
