<template>
	<v-dialog
		v-model="dialog"
		persistent
		fullscreen
		transition="dialog-bottom-transition"
		content-class="signup-dialog">
		<v-container class="signup-screen">
			<v-card class="pl-2 pr-2" elevation="0">
				<div class="d-flex align-center justify-space-between">
					<v-card-title class="text-h6 font-weight-bold padding-top-10"
						>Create your free account</v-card-title
					>
					<v-icon size="large" @click="close">icon-close-large</v-icon>
				</div>
				<v-card-title class="text-subtitle-1">Welcome to LiveSwitch</v-card-title>
				<v-card-title class="text-subtitle-2 pt-0 text-wrap">
					With your free account, we are giving you a 7-day free trial of our premium features. You will be
					able to continue using your free account once the trial expires.
				</v-card-title>
				<v-card-title class="text-subtitle-2 pt-0 text-wrap">Enjoy!</v-card-title>

				<v-form
					ref="form"
					:action="signupUrl"
					method="POST"
					@submit.prevent="handleSubmit"
					class="fourteen-free-form">
					<input type="hidden" name="token" ref="recaptchaTokenRef" />
					<input type="hidden" name="isTrial" value="true" />

					<v-row>
						<v-col cols="12">
							<v-text-field
								name="firstName"
								label="First Name*"
								type="text"
								placeholder="First Name"
								:rules="[(v) => !!v || 'First name is required']"
								outlined
								dense
								required
								hide-details="auto"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field
								name="lastName"
								label="Last Name*"
								type="text"
								placeholder="Last Name"
								:rules="[(v) => !!v || 'Last name is required']"
								outlined
								dense
								required
								hide-details="auto"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field
								name="orgName"
								label="Organization Name*"
								type="text"
								placeholder="Organization Name"
								:rules="[(v) => !!v || 'Organization name is required']"
								outlined
								dense
								required
								hide-details="auto">
								<template v-slot:append>
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-icon v-bind="attrs" v-on="on">icon-info-circle-stroke</v-icon>
										</template>
										This is the name that you want for your account.
									</v-tooltip>
								</template>
							</v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field
								name="email"
								label="Email*"
								type="email"
								placeholder="Email"
								:rules="[
									(v) => !!v || 'Email is required',
									(v) => /.+@.+\..+/.test(v) || 'Email must be valid',
								]"
								outlined
								dense
								required
								hide-details="auto"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field
								name="password"
								label="Password*"
								type="password"
								placeholder="Password"
								:rules="passwordRules"
								hint="Passwords must contain 6 or more characters, a number, an uppercase letter, and a lowercase letter."
								persistent-hint
								outlined
								dense
								required
								hide-details="auto"></v-text-field>
						</v-col>
					</v-row>

					<v-row class="mt-4">
						<v-col cols="6">
							<v-btn
								color="primary"
								rounded
								x-large
								block
								height="40"
								type="submit"
								class="text-subtitle-1">
								Create Free Account
							</v-btn>
						</v-col>
					</v-row>
					<input type="hidden" name="trialDurationDays" value="7" />
				</v-form>
			</v-card>
		</v-container>
	</v-dialog>
</template>

<script>
	export default {
		name: "SignupForm",

		data() {
			return {
				dialog: true, // Add this to control dialog visibility
				signupUrl: window.env.VITE_BRIDGE_SIGNUP_URL || "http://localhost:4141/signup",
				recaptchaToken: "",
				isFormValid: false,
				passwordRules: [
					(v) => !!v || "Password is required",
					(v) => v?.length >= 6 || "Password must be at least 6 characters",
					(v) => /\d/.test(v) || "Password must contain a number",
					(v) => /[A-Z]/.test(v) || "Password must contain an uppercase letter",
					(v) => /[a-z]/.test(v) || "Password must contain a lowercase letter",
				],
				isSubmitting: false,
				validationErrors: {},
			};
		},

		mounted() {
			this.loadReCaptchaScript();
			// disable scroll of project page
		},

		computed: {
			submitButtonText() {
				return this.isSubmitting ? "Creating Your Account..." : "Get Started!";
			},
		},

		methods: {
			async loadReCaptchaScript() {
				if (!document.getElementById("recaptcha-script")) {
					const script = document.createElement("script");
					script.id = "recaptcha-script";
					script.src =
						"https://www.google.com/recaptcha/enterprise.js?render=6LfmN1MqAAAAAIuWfNqiuzN_lsEKWbf5PLFlAEEX";
					script.async = true;
					document.head.appendChild(script);
				}
			},

			async getReCaptchaToken() {
				await this.loadReCaptchaScript();

				const siteKey = "6LfmN1MqAAAAAIuWfNqiuzN_lsEKWbf5PLFlAEEX";
				return new Promise((resolve, reject) => {
					grecaptcha.ready(async () => {
						const token = await grecaptcha.execute(siteKey, { action: "submit" });
						resolve(token);
					});
				});
			},

			async close() {
				this.dialog = false; // Update this to control dialog visibility
				this.$emit("close");
			},

			async validate() {
				return this.$refs.form.validate();
			},

			async handleSubmit(e) {
				const isValid = await this.$refs.form.validate();
				if (!isValid) {
					return;
				}

				try {
					this.recaptchaToken = await this.getReCaptchaToken();
					this.$refs.recaptchaTokenRef.value = this.recaptchaToken;
					e.target.submit();
				} catch (error) {
					console.error("Error getting reCAPTCHA token:", error);
				}
			},
		},
	};
</script>

<style scoped>
	.signup-dialog {
		border: unset !important;
	}

	.v-btn {
		text-transform: none !important;
	}

	.padding-top-10 {
		padding-top: 16px !important;
	}

	.signup-screen {
		width: 98vw;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px;
	}

	.text-wrap {
		word-break: normal;
	}

	/* Updated media query for dialog content */
	@media (min-width: 960px) {
		.signup-screen {
			max-width: 500px; /* or whatever width you prefer */
			margin: 0 auto;
			padding-top: 10vh;
			padding-bottom: 10vh;
		}
	}
</style>
