async function hasFreeTrialEnded(organization) {
	if (window.env.VITE_ENABLE_PURCHASE_MODULE === "false" || !organization) {
		return false;
	}
	if (organization.isTrial) {
		if (
			(organization.isEndedTrial && !organization.isSubscriptionActive) ||
			(organization.isTrialCCNow && !organization.isSubscriptionActive)
		) {
			return true;
		}
	}

	return false;
}

export { hasFreeTrialEnded };
