const parser = {
    parse: function(template, params){
        try{
            const names = Object.keys(params);
            const vals = Object.values(params);
            return new Function(...names, `return \`${template}\`;`)(...vals);
        }catch(e){
            console.error(e)
            return e.message
        }
    }
}
export default parser