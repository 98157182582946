const Projects = {
	getProjectItems: function (projectSelectItems: any[]): any[] {
		const projectSelectItemsUnique = [...new Set(projectSelectItems)];
		return projectSelectItemsUnique.map((customer) => {
			if (!customer) {
				return {};
			}
			const name = `${customer.name}`.trim();
			return Object.assign({}, customer, { name });
		});
	},
};

export default Projects;
