Number.prototype.isBetween = function (a, b) {
	const min = Math.min(a, b);
	const max = Math.max(a, b);
	return this >= min && this <= max;
};

export function safeGetItem(key) {
	try {
		return localStorage.getItem(key);
	} catch (e) {
		console.error("Error accessing localStorage:", e);
		return null;
	}
}

export function safeSetItem(key, value) {
	try {
		localStorage.setItem(key, value);
		return true;
	} catch (e) {
		console.error("Error setting localStorage item:", e);
		return false;
	}
}

export function getCustomerDisplayName(record) {
	if (!record) {
		return "";
	}
	if (record.name && record.name.trim()) {
		return record.name;
	} else if (record.phone) {
		return record.phone;
	} else if (record.email) {
		return record.email;
	} else if (record.PhoneNumbers && record.PhoneNumbers.length > 0) {
		return record.PhoneNumbers[0].phoneNumber;
	} else if (record.EmailAddresses && record.EmailAddresses.length > 0) {
		return record.EmailAddresses[0].emailAddress;
	}
	return "";
}
