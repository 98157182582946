class Account {
    constructor(data) {
      this.data = data
    }

    hasRole(role) {
        if (!role || role === 'undefined') {
            return false;
        }
        const roles = this.data.roles
        return !!roles.map(({ name }) => name)
            .includes(role);
    }

    hasPermission(permission) {
        if (!permission || permission === 'undefined') {
            return false;
        }
        if(!this.data){
            return false
        }
        const permissions = this.data.permissions
        return !!permissions.map(({ name }) => name)
            .includes(permission.name);
    }

    hasPermissionThroughRole(permission) {
        if (!permission || permission === 'undefined') {
            return false;
        }
        if(!this.data){
            return false
        }
        const roles = this.data.roles 
        if(!roles){
            return false
        }
        for(const item of roles){
            if(item.permissions.filter(p=>p.name == permission).length > 0){
                return true
            }
        }
        return false;
    }

    hasPermissionTo(permission) {
        if (!permission || permission === 'undefined') {
            return false;
        }
        return this.hasPermissionThroughRole(permission) || this.hasPermission(permission);
    }
}

export default Account