<template>
  <v-container>
    
    <v-row class="text-center">
      <v-col cols="12">
        <v-card
          elevation="2"
          loading
          outlined
          shaped
          tile
        >
          <v-card-text style="font-size:16px">Loading, please wait</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'LoadingPage',
    
  }
</script>
