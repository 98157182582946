import uuid from "./uuid";
import api from "../api";

let _token = null;
let _refreshTokenAfterTime = null;
let _browserId = null;

const tokenHandler = {
  getBrowserId: () => {
    if (!_browserId) {
      _browserId = uuid.uuidv4();
    }
    return _browserId;
  },
  getOrRefreshToken: async () => {    
    if (_token && _refreshTokenAfterTime && new Date().getTime() < _refreshTokenAfterTime) return _token;

    //test sending an expired token
    //if (_token) return _token;

    try {
      const browserId = tokenHandler.getBrowserId();
      const result = await api.createBrowserIdToken(browserId);
      _token = result.token;
      _refreshTokenAfterTime = new Date().getTime() + (result.ttl * 1000) - 60000; // within 1 minute of expiring      
    }
    catch (error) {
      console.error(`Error getting token from API server ${error.message}`, error);
      return null;
    }
    return _token;
  },
}
export default tokenHandler;