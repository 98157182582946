import DeviceUtility from "./device";

class Snapshot {
	static takeSnapshot(video, width, height, mirror, toBlob = true) {
		return new Promise(function (resolve, reject) {
			// Draw the 'video' element onto a temporary canvas.
			const canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = height;
			const canvasContext = canvas.getContext("2d");
			if (mirror) {
				canvasContext.translate(width, 0);
				canvasContext.scale(-1, 1);
			}
			canvasContext.drawImage(video, 0, 0, width, height);

			// Convert the canvas to a blob, and return the blob.
			if (toBlob) {
				canvas.toBlob(resolve, "image/png");
			} else {
				resolve(canvas.toDataURL("image/png"));
			}
		});
	}

	static async prepareForCropping(element) {
		let iosFallback = DeviceUtility.isIOS;
		let image = null;

		// Fallback for iOS CORS issue. The lightbox in combination with cropperjs does not play nicely
		// and the image fails CORS check because of caching so we use fetch to get the image and load it as a blob.
		if (iosFallback) {
			let src = element.src;
			const param = src.indexOf("?") == -1 ? `?v=${Date.now()}` : `&v=${Date.now()}`;
			src = `${src}${param}`;
			const response = await fetch(src, {
				"Cache-Control": "no-cache",
			});
			const blob = await response.blob();
			const base64 = URL.createObjectURL(blob);
			image = document.createElement("img");
			image.src = base64;
		} else {
			image = element.cloneNode();
			image.crossOrigin = "anonymous";
		}

		return image;
	}
}

export default Snapshot;
