<template>
	<v-snackbar v-model="display" :color="snackColor" :timeout="timeout" :top="true">
		<div>
			<h3 v-if="title">
				{{ title }}
				<v-btn v-if="timeout === -1" icon @click="display = false">
					<v-icon>icon-close-medium</v-icon>
				</v-btn>
			</h3>
			<p v-if="message">
				{{ message }}
			</p>
		</div>
	</v-snackbar>
</template>

<script>
	export default {
		data: () => ({
			display: false,
			timeout: 3000,
			title: null,
			resolve: null,
			reject: null,
			message: null,
			options: {
				color: "error",
				width: 440,
				zIndex: 200,
			},
			snackColor: "primary",
		}),
		methods: {
			open(title, message, snackColor = "") {
				this.snackColor = snackColor;
				this.display = true;
				this.title = title || null;
				this.message = message || null;
				return new Promise((resolve, reject) => {
					this.resolve = resolve;
					this.reject = reject;
				});
			},
			agree() {
				this.resolve(true);
				this.dialog = false;
			},
			cancel() {
				this.resolve(false);
				this.dialog = false;
			},
		},
	};
</script>
